<template>
  <section>
    auto logout
    
  </section>
</template>

<script>
import { logoutGeneral } from "@core/components/logout/logout.vue"


export default {
  components: {
    logoutGeneral,
  },
  created() {
    if(logoutGeneral() === true){
      // Redirect to login page
      //this.$router.push({ name: 'auth-login' })
      this.$router.replace('/login?status=again')
    }

  },
}
</script>
