<script>
import useJwt from '@/auth/jwt/useJwt'

export function logoutGeneral() {
  // Remove userData from localStorage
  // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

  // Remove userData from localStorage
  localStorage.removeItem('userData')

  return true
}

/* necessario per defualt - non rinuovere altrimenti la funzione "logoutGeneral" non viene esportata */
export default {
}
</script>
